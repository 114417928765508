export default [
  {
    header: 'PainelControle',
  },
  {
    title: 'leadinicio',
    icon: 'HomeIcon',
    route: 'app-inicio-user',
    action: 'user',
  },
  {
    title: 'leaduser',
    icon: 'UsersIcon',
    route: 'app-leadnovo',
    action: 'user',
  },
  {
    title: 'EmEsperauser',
    icon: 'AlertCircleIcon',
    route: 'app-espera-user',
    action: 'user',
  },
]
