export default [
  {
    header: 'Administracao',
  },
  {
    title: 'inicio',
    icon: 'HomeIcon',
    route: 'app-inicio',
    tagVariant: 'light-warning',
  },
  {
    title: 'empresa',
    icon: 'HomeIcon',
    route: 'app-empresa',
    tagVariant: 'light-warning',
  },
  {
    title: 'lead',
    icon: 'HomeIcon',
    route: 'app-lead-user',
    tagVariant: 'light-warning',
  },
  {
    title: 'Em Espera',
    icon: 'AlertCircleIcon',
    route: 'app-espera',
    tagVariant: 'light-warning',
  },
]
